class CyberTabs {
  constructor() {
    this.$tabs = $('.tabs__tab');
    this.$contents = $('.tabs__content');
    this.activeTabClass = 'tabs__tab--active';
    this.activeContentClass = 'tabs__content--active';

    this.bindTabsEvents();
  }

  bindTabsEvents() {
    this.$tabs.on('click', this.handleTabClick.bind(this));
  }

  handleTabClick(e) {
    e.preventDefault();

    const id = e.currentTarget.hash;

    this.handleTabsStates(id);
    this.handleContentsStates(id);
  }

  handleTabsStates(id) {
    this.$tabs.each((i, tab) => {
      $(tab).toggleClass(this.activeTabClass, tab.hash === id);
    });
  }

  handleContentsStates(id) {
    this.$contents.removeClass(this.activeContentClass);
    $(id).addClass(this.activeContentClass);
  }

  toString() {
    return `CyberTabs (${this.$tabs.length})`;
  }
}

class CyberNav {
  constructor() {
    this.$nav = $('.nav');
    this.$toggler = this.$nav.find('.nav__toggler');
    this.$navWrap = this.$nav.find('.nav__links');
    this.$htmlWrap = $('html, body');

    this.bindEvents();
  }

  bindEvents() {
    this.$toggler.on('click', this.togglerClick.bind(this));
    this.$navWrap.on('click', this.navClick.bind(this));
  }

  togglerClick() {
    this.$navWrap.toggleClass('nav__links--active');
  }

  navClick() {
    this.$navWrap.removeClass('nav__links--active');
  }

  toString() {
    return 'CyberNav';
  }
}

class CyberScrollable {
  constructor(el) {
    this.$el = $(el);
    this.$target = $(el.hash);
    this.$htmlWrap = $('html, body');

    // Config
    this.topOffset = 50;
    this.scrollDuration = 600;

    this.bindEvents();
  }

  bindEvents() {
    this.$el.on('click', this.onScrollableClick.bind(this));
  }

  onScrollableClick(e) {
    e.preventDefault();

    const offset = this.$target.data('scrollable-offset') || this.topOffset;

    this.$htmlWrap.stop().animate({
      scrollTop: this.$target.offset().top - offset
    }, this.scrollDuration);
  }

  toString() {
    return 'CyberScrollable';
  }
}

class CyberClient {
  constructor(el) {
    this.$el = $(el);
    this.bindEvents();
  }

  bindEvents() {
    this.$el.on('click', this.onClientClick.bind(this));
  }

  onClientClick(e) {
    this.$el.toggleClass('client--active');
  }
}

class CyberApp {
  constructor() {
    new CyberTabs();
    new CyberNav();

    $('.scrollable').each((i, el) => {
      new CyberScrollable(el);
    });

    $('.client').each((i, el) => {
      new CyberClient(el);
    });
  }

  toString() {
    return 'CyberApp';
  }
}

new CyberApp();
